import Phaser from 'phaser';
import GameScene from './main';

const sizes = {
    width: 650,
    height: 1280
}

class ShareScene extends Phaser.Scene {
    constructor() {
        super({
            key: 'ShareScene'
        });
        this.navigationHome;
        this.navigationShare;
        this.navigationGroups;
    }

    preload() {
        this.load.image("homenav", "/assets/homenav.png");
        this.load.image("sharenav", "/assets/sharenav.png");
        this.load.image("groupsnav", "/assets/groupsnav.png");
    }

    create() {
        // Bottom nav buttons
        this.navigationHome = this.add.image(30, sizes.height - 90, "homenav").setOrigin(0, 0).setDisplaySize(45, 45);
        this.navigationHome.setInteractive();
        this.navigationHome.on('pointerdown', () => this.transitionToScene('GameScene'));

        this.navigationGroups = this.add.image(sizes.width / 2 - 20, sizes.height - 90, "groupsnav").setOrigin(0, 0).setDisplaySize(45, 45);
        this.navigationGroups.setInteractive();
        // this.navigationGroups.on('pointerdown', () => this.transitionToScene('GroupsScene'));

        this.navigationShare = this.add.image(sizes.width - 100, sizes.height - 90, "sharenav").setOrigin(0, 0).setDisplaySize(45, 45);
        this.navigationShare.setInteractive();
        //  this.navigationShare.on('pointerdown', () => this.transitionToScene('AnotherScene'));
        this.cameras.main.fadeIn(1000, 0, 0, 0);
        // Score text
        this.textScore = this.add.text(sizes.width / 2 - 200, sizes.height / 2 - 100, "Share Page", { font: "80px arial bold", fill: "#ffffff" });
        // Optional: Add text or additional effects in your scene
    }

    update(time, delta) {
        // Update logic here if needed
    }

    // Define the callback functions
    onDown() {
        this.scene.start('scene-game');
    }

    onUp() {
        console.log("Button released");
        // Add your logic for the button up event
    }

    onShareDown() {
        console.log("Share button pressed");
        // Add your logic for the share button down event
    }

    onShareUp() {
        console.log("Share button released");
        // Add your logic for the share button up event
    }

    // Function to handle scene transition with a fade effect
    transitionToScene(targetScene) {
        this.cameras.main.fadeOut(300, 0, 0, 0);  // Fade out over 1 second (1000ms)

        this.time.delayedCall(300, () => {  // Wait for fade-out to complete
            this.scene.start(targetScene);   // Start the new scene
        });
    }
}

export default ShareScene;
